<template>
    <section v-if="show">
        <div id="form-job-vacancy-v2" class="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">{{ modalTitle }}</h2>
                </div>
                <ul class="uk-child-width-expand" uk-tab>
                    <li :class="[multiJobPost.activeTab === 0 && 'active']">
                        <a
                            class="uk-text-bold"
                            :class="[multiJobPost.activeTab === 0 && 'uk-text-primary']"
                            href="#"
                            @click="multiJobPost.activeTab = 0"
                        >
                            {{ modalTabTitle }}
                        </a>
                    </li>
                    <li :class="[multiJobPost.activeTab === 1 && 'active']">
                        <a
                            class="uk-text-bold"
                            :class="[multiJobPost.activeTab === 1 && 'uk-text-primary']"
                            href="#"
                            @click="multiJobPost.activeTab = 1"
                        >
                            Additional Question(s)
                        </a>
                    </li>
                </ul>

                <div class="uk-modal-body uk-padding-remove-top">
                    <AddSingleJobPost
                        v-if="multiJobPost.activeTab === 0 && multiJobPost.postType === 'single'"
                        :jobTypeOptions="jobTypeOptions"
                        :contractOptions="contractOptions"
                        :ageFilter="multiJobPost.ageFilter"
                        :genderOptions="genderOptions"
                        :educationLevelOptions="educationLevelOptions"
                        :docOptions="docOptions"
                        :additionalDocOptions="additionalDocOptions"
                        :jobPreferenceOptions="jobPreferenceOptions"
                        :testCategoryOptions="testCategoryOptions"
                        :adminJobVacancyOptions="adminJobVacancyOptions"
                        :formData="multiJobPost.formData"
                        :selectedData="selectedData"
                        :employer-company-id="employerCompanyId || ''"
                        @inValid="(multiJobPost.postIsValid = false)"
                        @valid="(multiJobPost.postIsValid = true)"
                    />
                    <AddMultiJobPost
                        v-else-if="multiJobPost.activeTab === 0 && multiJobPost.postType === 'multi'"
                        :jobTypeOptions="jobTypeOptions"
                        :contractOptions="contractOptions"
                        :ageFilter="multiJobPost.ageFilter"
                        :genderOptions="genderOptions"
                        :educationLevelOptions="educationLevelOptions"
                        :docOptions="docOptions"
                        :additionalDocOptions="additionalDocOptions"
                        :jobPreferenceOptions="jobPreferenceOptions"
                        :testCategoryOptions="testCategoryOptions"
                        :adminJobVacancyOptions="adminJobVacancyOptions"
                        :formData="multiJobPost.formData"
                        :roleOptions="openRoles"
                        :company="initiations.docs.length > 0 ? initiations.docs[0].company : {}"
                        @inValid="(multiJobPost.postIsValid = false)"
                        @valid="(multiJobPost.postIsValid = true)"
                    />
                    <AddQuestion
                        v-else
                        :formData="multiJobPost.formData"
                        @inValid="(multiJobPost.questionisValid = false)"
                        @valid="(multiJobPost.questionisValid = true)"
                    />
                </div>
                <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                    <button
                        v-if="multiJobPost.isLoading || stepperLoading"
                        class="uk-button uk-button-default uk-flex uk-flex-middle"
                        disabled
                    >
                        <span uk-spinner="ratio: 0.5"></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        class="uk-button"
                        :class="(!submitDisabled) ? 'uk-button-primary' : 'uk-button-default uk-text-lighter'"
                        :style="{ cursor: (!submitDisabled) ? 'pointer' : 'default', pointerEvents: (submitDisabled) ? 'none' : 'auto' }"
                        :disabled="submitDisabled"
                        @click="submitForm"
                    >{{ submitPattern }}</button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

import AddMultiJobPost from '@/components/pages/admin/job_vacancy/modals/AddMultiJobPost';
import AddQuestion from '@/components/pages/admin/job_vacancy/modals/AddQuestion';
import AddSingleJobPost from '@/components/pages/admin/job_vacancy/modals/AddSingleJobPost';
import moment from 'moment/moment';
import {notificationDanger, notificationSuccess} from '@/utils/notification';
import formatter from '@/utils/formatter';

const TITLE_TYPE = {
    single: 'Job Post Form',
    multi: 'Create Multiple Job Posts',
};

const FEE_FORMAT = {
    Month: 'Bulan',
    Day: 'Hari',
    Hour: 'Jam',
    Packet: 'Packet',
};

export default {
    name: 'ModalJobFormVacancyV2',
    components: {AddSingleJobPost, AddQuestion, AddMultiJobPost},
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        // these props maybe required for both update and create
        formType: {
            type: String,
            default: 'single',
        },
        startWork: {
            type: String,
            default: null,
        },
        dueDate: {
            type: String,
            default: null,
        },

        // these props maybe required only when update
        jobId: {
            type: String,
            default: null,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },

        // these props maybe required for create single job post
        selectedData: {
            type: Object,
            default: () => ({
                role: '',
                company: '',
                office: '',
            }),
        },
        selectedCompany: {
            type: Object,
            default: null
        },
        initiationDetail: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            jobTypeOptions: [],
            educationLevelOptions: [],
            testCategoryOptions: [],
            additionalDocOptions: [],
            contractOptions: [],
            genderOptions: [
                { label: "Pria", value: "male" },
                { label: "Wanita", value: "female" },
                { label: "Pria / Wanita", value: null },
            ],
            docOptions: [
                { label: "KTP", value: "ktp", $isDisabled: true },
                { label: "Ijazah", value: "ijazah" },
                { label: "SIM", value: "sim" },
                { label: "SKCK", value: "skck" },
                { label: "KK", value: "kk" },
            ],
            multiJobPost: {
                postType: 'single', // 'single' || 'multi'
                activeTab: 0,
                postIsValid: false,
                questionIsValid: true,
                isLoading: false,
                ageFilter: {
                    needed: false,
                    min: 16,
                    max: 61,
                },
                formData: {
                    id: null,
                    job_preference_id: null,
                    initiation_ids: [],
                    admin_job_vacancy_id: null,
                    role_id: '',
                    title: '',
                    job_description: '',
                    job_type_id: '',
                    benefits: null,
                    job_start: null,
                    working_hour_start: '',
                    working_hour_end: '',
                    gender: null,
                    age_group: null,
                    education_level_id: '',
                    skill_needed: '',
                    is_experience_needed: false,
                    required_tests: [],
                    required_docs: [{ label: 'KTP', value: 'ktp', $isDisabled: true }],
                    additional_docs: [],
                    job_contracts: [],
                    additional_questions: [],
                    question_usage: {
                        default: true,
                        auto_assign: false,
                        auto_assign_unreg: false,
                        migration: false,
                    },
                }
            },
            jobDetail: null,
            salarySetup: null,
            stepperLoading: false,
        };
    },
    watch: {
        formType(val){
            this.multiJobPost.postType = val;
        },
        async show(val) {
            if (val && this.isEdit && this.jobId) {
                await this.setJobDetailData();
                this.fillUpdateFormData();

                const hasKTPValue = this.multiJobPost.formData.required_docs.some(obj => obj.label === 'KTP');
                if (!hasKTPValue) { // check if not have it will append default KTP value
                    this.multiJobPost.formData.required_docs.push({ label: 'KTP', value: 'KTP', $isDisabled: true});
                }
            } else if (val && this.formType === 'single' && this.initiationDetail?._id) {
                await this.setSalarySetupData({
                    company_office_id: this.initiationDetail.office._id,
                    role_id: this.initiationDetail.role._id
                });
                this.fillPostFormData({ salarySetup: this.salarySetup });
            } else if (val && this.formType === 'multi') {
                this.fillMultiPostFormData();
            }
        },
    },
    computed: {
        ...mapGetters({
            openRoles: 'option_data/open_roles',
            initiations: 'initiation/initiations',
            jobPreferenceOptions: 'job_preference/job_preferences',
            adminJobVacancyOptions: 'admin_job_vacancy/admin_job_vacancies',
        }),
        employerCompanyId() {
            return this.selectedCompany?._id || null;
        },
        modalTitle(){
            return TITLE_TYPE[this.multiJobPost.postType];
        },
        modalTabTitle() {
            return this.isEdit ? 'Edit Job Post' : 'Create Job Post';
        },
        submitPattern() {
            return this.isEdit ? 'Continue' : 'Save';
        },
        submitDisabled() {
            return !this.multiJobPost.postIsValid || !this.multiJobPost.questionIsValid;
        },
        timezoneOffset() {
            return formatter.dateTimezoneOffset(new Date());
        },
        // feeUnitFormatter() {
        //     return FEE_FORMAT[this.salarySetup?.fee_unit] || '-';
        // },
    },
    async mounted() {
        try {
            await Promise.all([
                this.getJobPreferences({ limit: 1000, page: 1 }),
                this.getOpenRoles(),
                this.setJobTypeData(),
                this.setEducationLevelData(),
                this.setTestCategoryData(),
                this.setContractOptionData(),
                this.getAdditionalDocOptionData(),
            ]);
        } catch (e) {
            notificationDanger(e);
        }
    },
    methods: {
        ...mapActions({
            getOpenRoles: 'option_data/getOpenRoles',
            getJobPreferences: 'job_preference/getJobPreference',
            getJobTypes: 'option_data/getJobTypes',
            getEducationLevels: 'option_data/getEducationLevels',
            getTestCategories: 'option_data/getTestCategories',
            getSelectableContract: 'contract/getSelectableContract',
            getAdditionalDocument: 'initiation/getAdditionalDocument',
            getSalarySetup: 'initiation/getSalarySetup',
            getJobDetail: 'initiation/getJobDetails',
            updateJob: 'initiation/updateJob',
            postJob: 'initiation/postJob',
            postMultiJob: 'initiation/postMultiJob',
        }),
        async setJobTypeData() {
            this.jobTypeOptions = await this.getJobTypes();
        },
        async getAdditionalDocOptionData() {
            this.additionalDocOptions = await this.getAdditionalDocument();
        },
        async setEducationLevelData() {
            this.educationLevelOptions = await this.getEducationLevels();
        },
        async setTestCategoryData() {
            this.testCategoryOptions = await this.getTestCategories();
        },
        async setContractOptionData() {
            this.contractOptions = await this.getSelectableContract();
        },
        async setSalarySetupData({ company_office_id, role_id }) {
            try {
                const res = await this.getSalarySetup({ company_office_id, role_id });
                if (res?.status !== 'OK' || !res?.result) throw new Error(res?.message || 'Failed to get salary setup!');
                this.salarySetup = res.result;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setJobDetailData() {
            try {
                this.jobDetail = await this.getJobDetail(this.jobId);
                localStorage.setItem('job_detail_before_edit', JSON.stringify(this.jobDetail));
            } catch (error) {
                notificationDanger(error);
            }
        },
        async submitForm() {
            const timeout = 250;

            try {
                if (!this.isEdit) {
                    await this.saveJobPost();
                    return;
                }

                this.stepperLoading = true;
                let params = {};

                const {formData: form_data, ageFilter} = this.multiJobPost;
                form_data.age_group = ageFilter.needed && `${ageFilter.min} - ${ageFilter.max}` || null;

                params = {
                    start_work: this.startWork,
                    due_date: this.dueDate,
                    next: async () => (await this.editingJobPost({...form_data}, ageFilter)),
                    form_data,
                    timeout: timeout + 500,
                };

                setTimeout(() => {
                    this.stepperLoading = false;
                    this.hideModal({
                        timeout: timeout + 500,
                        eventListener: 'confirm',
                        eventParams: [params],
                    });
                }, timeout);
            } catch (e) {
                notificationDanger(e);
            }
        },
        async editingJobPost(formData, ageFilter) {
            let response = null;

            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                if (ageFilter?.needed) {
                    formData.age_group = `${ageFilter.min} - ${ageFilter.max}`;
                } else {
                    formData.age_group = null;
                }

                if (formData.working_hour_start.length > 6) {
                    formData.working_hour_start = moment(
                        formData.working_hour_start
                    ).format("HH:mm");
                } else if (
                    formData.working_hour_start == "Invalid date" ||
                    formData.working_hour_start == ""
                ) {
                    formData.working_hour_start = null;
                }
                if (formData.working_hour_end.length > 6) {
                    formData.working_hour_end = moment(
                        formData.working_hour_end
                    ).format("HH:mm");
                } else if (
                    formData.working_hour_end == "Invalid date" ||
                    formData.working_hour_end == ""
                ) {
                    formData.working_hour_end = null;
                }

                if (formData.admin_job_vacancy_id?._id) {
                    formData.admin_job_vacancy_id = formData.admin_job_vacancy_id._id;
                }

                const required_docs = formData.required_docs.map((d) => d.value);
                const additional_docs = formData.additional_docs.map((d) => d._id);
                const job_contracts = formData.job_contracts.map(d => d._id);
                const required_tests = formData.required_tests.map(d => ({
                    test_category_id: d._id,
                    minimum_score: d.minimum_score
                }));

                if (formData.benefits === null || formData.benefits === "") {
                    formData.benefits = null;
                }

                const job_preference_id = formData.job_preference_id._id;
                if (!formData.admin_job_vacancy_id) {
                    formData.admin_job_vacancy_id = formData.admin_job_vacancy_id?._id || formData.admin_job_vacancy_id;
                }

                // remove
                delete formData._id;
                delete formData.initiation_ids;
                delete formData.role_id;

                response = await this.updateJob({
                    ...formData, required_docs, job_contracts, required_tests, additional_docs, job_preference_id
                });

                if (!response || response?.status !== "OK" || !response?.result) {
                    throw new Error(response?.message || "Failed to update job!");
                }

                notificationSuccess("Updated!");
                return response;
            } catch (error) {
                notificationDanger(error);
                return false;
            }
        },
        async saveJobPost() {
            let eventListener = 'success';

            try {
                this.multiJobPost.isLoading = true;
                let apiEndPoint = "postJob";
                if (this.multiJobPost.ageFilter.needed) {
                    this.multiJobPost.formData.age_group = `${this.multiJobPost.ageFilter.min} - ${this.multiJobPost.ageFilter.max}`;
                } else {
                    this.multiJobPost.formData.age_group = null;
                }
                if (this.multiJobPost.formData.working_hour_start === 'Invalid date' || this.multiJobPost.formData.working_hour_start === '') {
                    this.multiJobPost.formData.working_hour_start = null;
                } else {
                    this.multiJobPost.formData.working_hour_start = moment(this.multiJobPost.formData.working_hour_start).format('HH:mm');
                }
                if (this.multiJobPost.formData.working_hour_end === 'Invalid date' || this.multiJobPost.formData.working_hour_end === '') {
                    this.multiJobPost.formData.working_hour_end = null;
                } else {
                    this.multiJobPost.formData.working_hour_end = moment(this.multiJobPost.formData.working_hour_end).format('HH:mm');
                }
                const required_docs = this.multiJobPost.formData.required_docs.map((d) => d.value);
                const additional_docs = this.multiJobPost.formData.additional_docs.map((d) => d._id);
                const job_contracts = this.multiJobPost.formData.job_contracts.map(d => d._id);
                const required_tests = this.multiJobPost.formData.required_tests.map(d => ({
                    test_category_id: d._id,
                    minimum_score: d.minimum_score
                }));
                if (this.multiJobPost.formData.benefits === null || this.multiJobPost.formData.benefits === '') {
                    this.multiJobPost.formData.benefits = null;
                }

                this.multiJobPost.formData.job_preference_id = this.multiJobPost.formData.job_preference_id._id;

                if (this.multiJobPost.formData.admin_job_vacancy_id !== null) {
                    this.multiJobPost.formData.admin_job_vacancy_id = this.multiJobPost.formData.admin_job_vacancy_id._id;
                }

                // delete unused data
                if (this.multiJobPost.postType === 'single') {
                    delete this.multiJobPost.formData.initiation_ids;
                    delete this.multiJobPost.formData.role_id;
                } else {
                    apiEndPoint = "postMultiJob";
                    delete this.multiJobPost.formData.id;
                }
                const response = await this[apiEndPoint]({
                    ...this.multiJobPost.formData, required_docs, job_contracts, required_tests, additional_docs
                });
                this.multiJobPost.isLoading = false;

                if (!response || response?.status !== 'OK') {
                    throw new Error(response?.message || 'Failed to save job!');
                }

                notificationSuccess('Job saved!');
                await this.getOpenRoles();
            } catch (error) {
                eventListener = 'cancel';
                notificationDanger(error);
            } finally {
                this.multiJobPost.isLoading = false;
                this.hideModal({ timeout: 500, eventListener });
            }
        },
        hideModal({ timeout = 500, eventListener = 'cancel', eventParams = [] }) {
            if (timeout > 0 && eventParams.length === 0) {
                eventParams.push(timeout);
            }

            this.resetForm();
            window.UIkit.modal('#form-job-vacancy-v2').$destroy(true);

            this.$emit(eventListener, ...eventParams);
        },
        resetForm() {
            this.multiJobPost = {
                postType: 'single',
                activeTab: 0,
                postIsValid: false,
                questionIsValid: true,
                isLoading: false,
                ageFilter: {
                    needed: false,
                    min: 16,
                    max: 61,
                },
                formData: {
                    id: null,
                    job_preference_id: null,
                    initiation_ids: [],
                    admin_job_vacancy_id: null,
                    role_id: '',
                    title: '',
                    job_description: '',
                    job_type_id: '',
                    benefits: null,
                    job_start: null,
                    working_hour_start: '',
                    working_hour_end: '',
                    gender: null,
                    age_group: null,
                    education_level_id: '',
                    skill_needed: '',
                    is_experience_needed: false,
                    required_tests: [],
                    required_docs: [{ label: 'KTP', value: 'ktp', $isDisabled: true }],
                    additional_docs: [],
                    job_contracts: [],
                    additional_questions: [],
                    question_usage: {
                        default: true,
                        auto_assign: false,
                        auto_assign_unreg: false,
                        migration: false,
                    },
                }
            };
        },
        fillUpdateFormData() {
            try {
                this.multiJobPost.formData.id = this.jobDetail._id;
                this.multiJobPost.formData.job_preference_id = this.jobPreferenceOptions.find(
                    jobPreference => jobPreference._id === this.jobDetail.job_preference_id
                );
                this.multiJobPost.formData.admin_job_vacancy_id = this.adminJobVacancyOptions.find(
                    adminJobVacancy => adminJobVacancy._id === this.jobDetail.admin_job_vacancy_id
                );
                this.multiJobPost.formData.title = this.jobDetail.title;
                this.multiJobPost.formData.job_description = this.jobDetail.job_description;
                this.multiJobPost.formData.salary = this.jobDetail.salary;
                this.multiJobPost.formData.salary_overtime_hourly = this.jobDetail.salary_overtime_hourly;
                this.multiJobPost.formData.salary_unit = this.jobDetail.salary_unit;
                this.multiJobPost.formData.job_type_id = this.jobDetail.job_type?._id || null;
                this.multiJobPost.formData.benefits = this.jobDetail.benefits;

                const startWork = this.startWork ? this.startWork.replace(this.timezoneOffset, 'Z') : this.jobDetail.job_start;
                this.multiJobPost.formData.job_start = startWork;

                if (this.jobDetail.working_hour_start !== null) {
                    this.multiJobPost.formData.working_hour_start = this.jobDetail.working_hour_start;
                }

                if (this.jobDetail.working_hour_end !== null) {
                    this.multiJobPost.formData.working_hour_end = this.jobDetail.working_hour_end;
                }

                this.multiJobPost.formData.gender = this.jobDetail.gender;
                this.multiJobPost.formData.education_level_id = this.jobDetail.education_level?._id;
                this.multiJobPost.formData.is_experience_needed = this.jobDetail.is_experience_needed;
                this.multiJobPost.formData.skill_needed = this.jobDetail.skill_needed;
                this.multiJobPost.formData.additional_questions = this.jobDetail.additional_questions;

                if (this.jobDetail.question_usage && Object.keys(this.jobDetail.question_usage).length > 0) {
                    this.multiJobPost.formData.question_usage = this.jobDetail.question_usage;
                }

                if (this.jobDetail.age_group) {
                    const item_age = this.jobDetail.age_group.split(" - ");
                    this.multiJobPost.ageFilter.needed = true;
                    this.multiJobPost.ageFilter.min = parseInt(item_age[0]);
                    this.multiJobPost.ageFilter.max = parseInt(item_age[1]);
                }

                if (this.jobDetail.required_docs && this.jobDetail.required_docs.length > 0) {
                    this.jobDetail.required_docs.forEach((doc) => {
                        const docOption = this.docOptions.find((d) => d.value === doc);
                        const currentDocOption = this.multiJobPost.formData.required_docs.find((d) => d.value === doc);

                        if (docOption && !currentDocOption) {
                            this.multiJobPost.formData.required_docs.push(docOption);
                        }
                    });
                }

                if (this.jobDetail.additional_docs && this.jobDetail.additional_docs.length > 0) {
                    this.jobDetail.additional_docs.forEach((doc) => {
                        const docOption = this.additionalDocOptions.find((d) => d._id === doc._id);

                        if (docOption) {
                            this.multiJobPost.formData.additional_docs.push(docOption);
                        }
                    });
                }

                if (this.jobDetail.required_tests && this.jobDetail.required_tests.length > 0) {
                    this.jobDetail.required_tests.forEach((element) => {
                        this.multiJobPost.formData.required_tests.push({
                            _id: element.test_category_id._id,
                            name: element.test_category_id.name,
                            minimum_score: element.minimum_score,
                        });
                    });
                }

                if (this.jobDetail.job_contracts && this.jobDetail.job_contracts.length > 0) {
                    this.jobDetail.job_contracts.forEach((element) => {
                        this.multiJobPost.formData.job_contracts.push(
                            this.contractOptions.find((e) => e._id === element._id)
                        );
                    });
                }

                delete this.multiJobPost.formData.initiation_ids;
                delete this.multiJobPost.formData.role_id;
            } catch (error) {
                notificationDanger(error);
            }
        },
        fillPostFormData({ salarySetup }) {
            try {
                this.multiJobPost.postType = this.formType || 'single';

                const startWork = this.startWork ? this.startWork.replace(this.timezoneOffset, 'Z') : this.initiationDetail.start_work;
                this.multiJobPost.formData.job_start = startWork;
                this.multiJobPost.formData.id = this.initiationDetail._id;

                this.multiJobPost.formData.salary = salarySetup?.fee_normal || 0;
                this.multiJobPost.formData.salary_overtime_hourly = salarySetup?.fee_overtime_hourly || 0;
                this.multiJobPost.formData.salary_unit = FEE_FORMAT[salarySetup?.fee_normal_unit] || '-';
            } catch (e) {
                notificationDanger(e);
            }
        },
        fillMultiPostFormData() {
            try {
                this.multiJobPost.formData.initiation_ids = this.initiations.docs.map((initiation) => initiation._id);
                this.multiJobPost.formData.role_id = this.$route.query.role_id || this.selectedData.role._id;
            } catch (e) {
                notificationDanger(e);
            }
        },
    },
};
</script>
<style scoped>
.button-warning {
    background-color: #F5C145;
}

button.button-warning:disabled {
    background-color: #A6A6A6;
}

.padding-left-right-15 {
    padding: 0 15px;
}

.title-confirmation {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
}

.button-back {
    color: #1e87f0;
    border: 1px solid #1e87f0;
}

.title-send-blast {
    color: #DF5C70;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
</style>
